import * as React from 'react';

export const FileShieldLineIcon = ({
  ref,
  ...props
}: React.SVGProps<SVGSVGElement> & {
  ref?: React.RefObject<SVGSVGElement>;
}) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
    ref={ref}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.255 22C11.833 21.396 11.505 20.722 11.29 20H5V4H15V8H19V11.67L21 11.674V7L16 2H3.999C3.447 2 3 2.444 3 2.992V21.008C3.00209 21.2706 3.10742 21.5219 3.29322 21.7075C3.47902 21.8931 3.73038 21.9982 3.993 22H12.255ZM22 13H13V18.6677C13.0012 19.1338 13.1238 19.5922 13.357 20.0019C13.5902 20.4116 13.9266 20.7597 14.336 21.0149L17.5 23L20.664 21.0149C21.499 20.4919 22 19.6098 22 18.6668V13ZM15.436 19.4241C15.162 19.2517 15 18.9678 15 18.6668V14.9051H20V18.6668C19.999 18.8176 19.9586 18.9657 19.8825 19.0979C19.8064 19.2301 19.6969 19.3422 19.564 19.4241L17.5 20.7196L15.436 19.4241Z'
      fill='#113481'
    />
  </svg>
);
