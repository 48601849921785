import clsx from 'clsx';
import * as React from 'react';
import { useLocation } from 'react-router';
import { useTheme } from '~/common/context';
import { Footer, NavbarTop, NavbarVertical } from '~/common/components';

export default function AppLayout(props: React.PropsWithChildren) {
  const { pathname } = useLocation();

  const { isFluid, navbarPosition } = useTheme();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        <NavbarVertical />
      )}
      <div className={clsx('content')}>
        <NavbarTop />
        {/*------ Main Routes ------*/}
        {props.children}
        <Footer />
      </div>
    </div>
  );
}
