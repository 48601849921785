import * as React from 'react';

export const ShowersLineIcon = ({
  ref,
  ...props
}: React.SVGProps<SVGSVGElement> & {
  ref?: React.RefObject<SVGSVGElement>;
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    {...props}
    ref={ref}
  >
    <path
      d='M4.99975 16.9297C3.89314 16.2899 2.95595 15.3943 2.26667 14.3178C1.57739 13.2413 1.1561 12.0153 1.03808 10.7425C0.92006 9.46976 1.10875 8.18722 1.58834 7.00235C2.06793 5.81749 2.82446 4.76479 3.79457 3.93243C4.76467 3.10008 5.92011 2.5123 7.16409 2.21834C8.40808 1.92437 9.7044 1.93277 10.9445 2.24284C12.1845 2.5529 13.3323 3.1556 14.2915 4.00046C15.2507 4.84531 15.9936 5.90773 16.4578 7.09871C17.8531 6.82289 19.301 7.09833 20.4975 7.86721C21.6941 8.63609 22.5463 9.83862 22.8752 11.2224C23.2042 12.6061 22.9842 14.0635 22.2616 15.2886C21.539 16.5137 20.3699 17.4112 18.9998 17.7927V15.6627C19.4619 15.4434 19.8704 15.1254 20.1965 14.7312C20.5225 14.337 20.7581 13.8761 20.8868 13.381C21.0155 12.8858 21.0341 12.3685 20.9412 11.8655C20.8484 11.3624 20.6464 10.8858 20.3495 10.4692C20.0525 10.0526 19.6679 9.70617 19.2226 9.45429C18.7774 9.2024 18.2823 9.05119 17.7723 9.01129C17.2623 8.9714 16.7497 9.04379 16.2707 9.22337C15.7917 9.40296 15.3578 9.68536 14.9998 10.0507V9.99971C14.9998 8.99887 14.7494 8.01392 14.2715 7.13454C13.7936 6.25517 13.1033 5.50932 12.2635 4.9649C11.4237 4.42049 10.461 4.0948 9.46317 4.0175C8.46531 3.9402 7.46397 4.11374 6.55033 4.52232C5.63668 4.9309 4.83976 5.56155 4.23212 6.35682C3.62448 7.1521 3.22544 8.08674 3.07132 9.07565C2.9172 10.0646 3.01291 11.0763 3.34973 12.0188C3.68654 12.9612 4.25377 13.8045 4.99975 14.4717V16.9297ZM6.99975 15.9997H8.99975V19.9997H6.99975V15.9997ZM14.9998 15.9997H16.9998V19.9997H14.9998V15.9997ZM10.9998 18.9997H12.9998V22.9997H10.9998V18.9997Z'
      fill='#5D6E82'
    />
  </svg>
);
