import 'react-datepicker/dist/react-datepicker.css';

import * as React from 'react';
import ReactDatePicker, {
  registerLocale,
  ReactDatePickerProps,
} from 'react-datepicker';
import { Button, Flex } from '../index';
import fr from 'date-fns/locale/fr';
export type DateTimePickerProps<
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined,
> = Pick<
  ReactDatePickerProps<CustomModifierNames, WithRange>,
  | 'placeholderText'
  | 'dateFormat'
  | 'onChange'
  | 'selected'
  | 'showTimeSelect'
  | 'selectsRange'
  | 'startDate'
  | 'endDate'
  | 'formatWeekDay'
  | 'monthsShown'
  | 'minDate'
  | 'disabled'
> & {
  id?: string;
  name: string;
  helperText?: string;
  label?: string;
  readOnly?: boolean;
  required?: boolean;
  translations?: Partial<{
    resetButton: string;
    applyButton: string;
    todayButton: string;
  }>;
};

type DatePickRefApi = {
  readonly setOpen: (open: boolean, skipSetBlur?: boolean) => void;
  readonly clear: () => void;
};
function DatePicker<
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined,
>(props: DateTimePickerProps<CustomModifierNames, WithRange>) {
  const calRef = React.useRef(null);
  const DatePickeRef = calRef.current as unknown as DatePickRefApi | null;
  React.useLayoutEffect(() => {
    // lazy load the local module
    registerLocale('fr', fr);
  }, []);
  return (
    <ReactDatePicker<CustomModifierNames, WithRange>
      ref={calRef}
      placeholderText='DD-MM-YYYY h:mm aa'
      dateFormat='dd-MM-yyyy h:mm aa'
      showTimeSelect
      {...props}
      className='form-control'
      autoComplete='off'
      timeIntervals={5}
      shouldCloseOnSelect={false}
      fixedHeight
      selected={props.selected}
      onChange={props.onChange}
      locale={'fr'} // TODO: make this after adding preferred locale to the connected user
    >
      <div>
        <Flex
          alignItems='center'
          justifyContent='end'
          className='py-2 space-x-4'
        >
          <Button
            id='reset-datepicker'
            variant='secondary'
            onClick={() => {
              DatePickeRef?.clear();
              DatePickeRef?.setOpen(false);
            }}
          >
            {props.translations?.resetButton || 'Reset'}
          </Button>
          <Button
            id='apply-datepicker'
            variant='primary'
            onClick={() => {
              DatePickeRef?.setOpen(false);
            }}
          >
            {props.translations?.applyButton || 'Apply'}
          </Button>
        </Flex>
      </div>
    </ReactDatePicker>
  );
}

export default DatePicker;
