import * as React from 'react';

export const ClipBoardIcon = ({
  ref,
  ...props
}: React.SVGProps<SVGSVGElement> & {
  ref?: React.RefObject<SVGSVGElement>;
}) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
    ref={ref}
  >
    <path d='M8 11H16V13H8V11Z' fill='currentColor' />
    <path d='M8 15H16V17H8V15Z' fill='currentColor' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7 2V4H3.993C3.445 4 3 4.445 3 4.993V21.007C3.00026 21.2703 3.10497 21.5227 3.29114 21.7089C3.4773 21.895 3.72972 21.9997 3.993 22H20.007C20.2703 21.9997 20.5227 21.895 20.7089 21.7089C20.895 21.5227 20.9997 21.2703 21 21.007V4.993C21 4.445 20.555 4 20.007 4H17V2H7ZM5 6H7V8H17V6H19V20H5V6ZM9 6V4H15V6H9Z'
      fill='currentColor'
    />
  </svg>
);
