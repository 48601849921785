import * as React from 'react';

export const ArrowDownIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M11.5464 15.4075L6.18557 10.2733C5.93814 10.0086 5.93814 9.61164 6.18557 9.37346L6.81787 8.76476C7.06529 8.52657 7.47766 8.52657 7.75258 8.76476L11.9863 12.8404L16.2474 8.76476C16.5223 8.52657 16.9347 8.52657 17.1821 8.76476L17.8144 9.37346C18.0619 9.61164 18.0619 10.0086 17.8144 10.2733L12.4536 15.4075C12.2062 15.6457 11.7938 15.6457 11.5464 15.4075Z'
      fill='currentColor'
    />
  </svg>
);

export const ArrowUpIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M12.4536 8.59252L17.8144 13.7267C18.0619 13.9914 18.0619 14.3884 17.8144 14.6265L17.1821 15.2352C16.9347 15.4734 16.5223 15.4734 16.2474 15.2352L12.0137 11.1596L7.75258 15.2352C7.47766 15.4734 7.06529 15.4734 6.81787 15.2352L6.18557 14.6265C5.93814 14.3884 5.93814 13.9914 6.18557 13.7267L11.5464 8.59252C11.7938 8.35433 12.2062 8.35433 12.4536 8.59252Z'
      fill='currentColor'
    />
  </svg>
);
export const ArrowRightIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M15.3214 12.5395L10.1871 17.9004C9.9225 18.1478 9.52552 18.1478 9.28734 17.9004L8.67864 17.2681C8.44045 17.0206 8.44045 16.6083 8.67864 16.3334L12.7543 12.0997L8.67864 7.83851C8.44045 7.5636 8.44045 7.15123 8.67864 6.90381L9.28733 6.2715C9.52552 6.02408 9.9225 6.02408 10.1871 6.2715L15.3214 11.6323C15.5595 11.8798 15.5595 12.2921 15.3214 12.5395Z'
      fill='currentColor'
    />
  </svg>
);
