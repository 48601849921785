import clsx from 'clsx';
import { useState } from 'react';
import { Collapse, Nav } from '@wyz/ui';
import { NavLink, useLocation } from 'react-router';
import { useI18n, useTheme } from '~/common/context';
import {
  RouteDefinition,
  SeparatorDefinition,
  SideBarRoute,
  TranslationKeys,
} from '~/common/types';
import { isObject } from 'lodash-es';
// eslint-disable-next-line @wyz/module/no-relative-import
import NavbarVerticalMenuItem from './navbar-vertical-menu-item';

const CollapseItems = ({ route }: CollapseItemsProps) => {
  const { pathname } = useLocation();

  const openCollapse = (children: Array<RouteDefinition<string>>) => {
    const checkLink = (child: RouteDefinition<string>) => {
      if (child.to === pathname) {
        return true;
      }
      return (
        Object.prototype.hasOwnProperty.call(children, 'children') &&
        child.children?.some(checkLink)
      );
    };
    return children.some(checkLink);
  };

  const [open, setOpen] = useState<boolean>(openCollapse(route.children || []));
  return (
    <Nav.Item as='li'>
      <Nav.Link
        onClick={() => {
          setOpen(!open);
        }}
        className={clsx('dropdown-indicator cursor-pointer')}
        aria-expanded={open}
        data-cy={route.dataCy}
      >
        <NavbarVerticalMenuItem route={route} />
      </Nav.Link>
      <Collapse in={open}>
        <Nav className='flex-column nav' as='ul'>
          {route.children && <NavbarVerticalMenu routes={route.children} />}
        </Nav>
      </Collapse>
    </Nav.Item>
  );
};

type CollapseItemsProps = {
  route: RouteDefinition<keyof TranslationKeys>;
};

type NavbarVerticalMenuProps = {
  routes: SideBarRoute<keyof TranslationKeys>['children'];
};
function isSeparator<T>(arg: unknown): arg is SeparatorDefinition<T> {
  return isObject(arg) && 'separator' in arg && arg.separator === true;
}
const NavbarVerticalMenu = ({ routes }: NavbarVerticalMenuProps) => {
  const { t } = useI18n();

  const { showBurgerMenu, setConfig } = useTheme();
  const handleNavItemClick = () => {
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
  };
  return (
    <>
      {routes.map((route) => {
        if (isSeparator(route)) {
          return (
            <li key={`${route.name}.separator`} className='nav-item'>
              <div className='mt-3 mb-2 navbar-vertical-label-wrapper row'>
                <div className='navbar-vertical-label navbar-vertical-label col-auto'>
                  {t(route.name)}
                </div>
                <div className='ps-0 col'>
                  <hr className='mb-0 navbar-vertical-divider' />
                </div>
              </div>
            </li>
          );
        } else {
          if (!route.children) {
            return (
              <Nav.Item as='li' key={route.name} onClick={handleNavItemClick}>
                <NavLink
                  end={route.exact}
                  to={route.to}
                  className={({ isActive }) =>
                    isActive ? 'active nav-link' : 'nav-link'
                  }
                  data-cy={route.dataCy}
                  target={route.externalTarget ? '_blank' : undefined}
                >
                  <NavbarVerticalMenuItem route={route} />
                </NavLink>
              </Nav.Item>
            );
          }
          return <CollapseItems route={route} key={route.name} />;
        }
      })}
    </>
  );
};

export default NavbarVerticalMenu;
