import { ConfirmationProvider, FullPageSpinner, ToastProvider } from '@wyz/ui';
import { QueryClient, QueryClientProvider } from '@wyz/utils';
import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router';
import AppLayout from '~/common/app-layout';
import {
  ApiProvider,
  AuthProvider,
  ConfigProvider,
  DatatableProvider,
  I18nProvider,
  ThemeProvider,
} from '~/common/context';

const WyzSettingsRouter = React.lazy(() => import('./settings/router'));
const WyzOrdersRouter = React.lazy(() => import('./orders/router'));

const queryClient = new QueryClient();

const AppsRouter = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider>
        <BrowserRouter>
          <I18nProvider>
            <AuthProvider>
              <ApiProvider>
                <ConfirmationProvider>
                  <ThemeProvider>
                    <ToastProvider>
                      <DatatableProvider>
                        <AppLayout>
                          <React.Suspense fallback={<FullPageSpinner />}>
                            <Routes>
                              <Route
                                path='/app/orders/*'
                                element={<WyzOrdersRouter />}
                              ></Route>
                              <Route
                                path='/app/settings/*'
                                element={<WyzSettingsRouter />}
                              ></Route>
                              <Route
                                path='/*'
                                element={<WyzSettingsRouter />}
                              ></Route>
                            </Routes>
                          </React.Suspense>
                        </AppLayout>
                      </DatatableProvider>
                    </ToastProvider>
                  </ThemeProvider>
                </ConfirmationProvider>
              </ApiProvider>
            </AuthProvider>
          </I18nProvider>
        </BrowserRouter>
      </ConfigProvider>
    </QueryClientProvider>
  );
};

export default AppsRouter;
