/* eslint-disable @typescript-eslint/no-explicit-any */
import { IFormConfigurationContext } from '@wyz/types';
import { HTMLProps } from 'react';
import {
  FieldValues,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  UseFormReturn,
} from 'react-hook-form';
import { ConfiguredFormProvider } from './configured-form-provider';

export type FormMethods<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
> = UseFormReturn<TFieldValues, TContext, TTransformedValues>;

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
> extends HTMLProps<HTMLFormElement> {
  methods: FormMethods<TFieldValues, TContext, TTransformedValues>;
  submitHandler: SubmitHandler<TFieldValues>;
  failureHandler?: SubmitErrorHandler<TFieldValues>;
  formConfiguration?: IFormConfigurationContext;
}

const DEFAULT_CONFIGURATION: IFormConfigurationContext = { mode: 'WRITE' };
function ControlsFormInner<T extends FieldValues>({
  formConfiguration = DEFAULT_CONFIGURATION,
  ...props
}: Props<T>) {
  const { submitHandler, failureHandler, methods, ...rest } = props;
  const { handleSubmit } = methods;
  return (
    <ConfiguredFormProvider {...formConfiguration}>
      <FormProvider {...methods}>
        <form
          {...rest}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(submitHandler, failureHandler)}
          // className={
          //   Object.keys(props.methods.formState.errors).length > 0
          //     ? 'was-validated'
          //     : ''
          // }
          noValidate
        />
      </FormProvider>
    </ConfiguredFormProvider>
  );
}

export default ControlsFormInner;
