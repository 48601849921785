import * as React from 'react';

export const UploadLineIcon = ({
  ref,
  ...props
}: React.SVGProps<SVGSVGElement> & {
  ref?: React.RefObject<SVGSVGElement>;
}) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
    ref={ref}
  >
    <g clipPath='url(#clip0_11111_34142)'>
      <path
        d='M2.5 15.8327H17.5V17.4993H2.5V15.8327ZM10.8333 4.85602V14.166H9.16667V4.85602L4.1075 9.91601L2.92917 8.73768L10 1.66602L17.0708 8.73685L15.8925 9.91518L10.8333 4.85768V4.85602Z'
        fill='#113481'
      />
    </g>
    <defs>
      <clipPath id='clip0_11111_34142'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
