import BsDropdown from 'react-bootstrap/Dropdown';
import * as React from 'react';
import { DropdownItemProps } from 'react-bootstrap/esm/DropdownItem';
import { DropdownToggleProps } from 'react-bootstrap/esm/DropdownToggle';

type ContainerProps = React.PropsWithChildren<{
  as?: React.ElementType;
  navbar?: boolean;
}>;

function Container({ children, ...props }: ContainerProps) {
  return <BsDropdown {...props}>{children}</BsDropdown>;
}

const __DEFAULT_ITEM__ = 'a';
type ItemOwnProps<E extends React.ElementType = typeof __DEFAULT_ITEM__> = Pick<
  DropdownItemProps,
  'eventKey'
> & {
  as?: E;
  id: string;
};

export type ItemProps<E extends React.ElementType> = ItemOwnProps<E> &
  Omit<React.ComponentProps<E>, keyof ItemOwnProps>;

function InnerItem<T extends React.ElementType = typeof __DEFAULT_ITEM__>({
  children,
  ...props
}: ItemProps<T>) {
  return (
    // @ts-expect-error TODO some other day
    <BsDropdown.Item {...props} data-cy={props.id}>
      {children}
    </BsDropdown.Item>
  );
}
const Item = InnerItem;

const __DEFAULT_TOGGLER__ = 'button';
type TogglerOwnProps<E extends React.ElementType = typeof __DEFAULT_TOGGLER__> =
  Pick<DropdownToggleProps, 'bsPrefix' | 'split'> & {
    as?: E;
    id: string;
  };

export type TogglerProps<E extends React.ElementType> = TogglerOwnProps<E> &
  Omit<React.ComponentProps<E>, keyof TogglerOwnProps>;

function InnerToggle<T extends React.ElementType = typeof __DEFAULT_TOGGLER__>(
  props: TogglerProps<T> & {
    id: string;
    children?: React.ReactNode;
  },
) {
  // @ts-expect-error TODO some other day
  return <BsDropdown.Toggle {...props} data-cy={props.id} />;
}
const Toggle = InnerToggle;

const Dropdown = {
  Container,
  Item: Item,
  Toggle: Toggle,
  Divider: BsDropdown.Divider,
  Header: BsDropdown.Header,
  Menu: BsDropdown.Menu,
  ItemText: BsDropdown.ItemText,
};

export default Dropdown;
