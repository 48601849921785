import * as React from 'react';

export const CustomerServiceIcon = ({
  ref,
  ...props
}: React.SVGProps<SVGSVGElement> & {
  ref?: React.RefObject<SVGSVGElement>;
}) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
    ref={ref}
  >
    <path
      d='M17.4999 6.66667C17.9419 6.66667 18.3659 6.84226 18.6784 7.15482C18.991 7.46738 19.1666 7.8913 19.1666 8.33333V11.6667C19.1666 12.1087 18.991 12.5326 18.6784 12.8452C18.3659 13.1577 17.9419 13.3333 17.4999 13.3333H16.6149C16.4118 14.9444 15.6276 16.426 14.4097 17.5C13.1918 18.574 11.6238 19.1666 9.99992 19.1667V17.5C11.326 17.5 12.5978 16.9732 13.5355 16.0355C14.4731 15.0979 14.9999 13.8261 14.9999 12.5V7.5C14.9999 6.17392 14.4731 4.90215 13.5355 3.96446C12.5978 3.02678 11.326 2.5 9.99992 2.5C8.67384 2.5 7.40207 3.02678 6.46438 3.96446C5.5267 4.90215 4.99992 6.17392 4.99992 7.5V13.3333H2.49992C2.05789 13.3333 1.63397 13.1577 1.32141 12.8452C1.00885 12.5326 0.833252 12.1087 0.833252 11.6667V8.33333C0.833252 7.8913 1.00885 7.46738 1.32141 7.15482C1.63397 6.84226 2.05789 6.66667 2.49992 6.66667H3.38492C3.58828 5.05574 4.37248 3.57439 5.5904 2.50056C6.80831 1.42673 8.37621 0.834229 9.99992 0.834229C11.6236 0.834229 13.1915 1.42673 14.4094 2.50056C15.6273 3.57439 16.4116 5.05574 16.6149 6.66667H17.4999ZM6.46659 13.1542L7.34992 11.7408C8.14415 12.2384 9.06272 12.5015 9.99992 12.5C10.9371 12.5015 11.8557 12.2384 12.6499 11.7408L13.5333 13.1542C12.4743 13.8176 11.2495 14.1686 9.99992 14.1667C8.7503 14.1686 7.52554 13.8176 6.46659 13.1542Z'
      fill='white'
    />
  </svg>
);
