/* eslint-disable @wyz/i18n/no-plain-text */
import { Dropdown, Icon } from '@wyz/ui';
import { useAuth } from 'react-oidc-context';
import { Link } from 'react-router';
import { useI18n } from '~/common/context';
import { log } from '~/common/helpers';

const ProfileDropdown = () => {
  const auth = useAuth();
  const config = {
    redirect_uri: location.origin,
  };
  const logout = () => {
    auth
      .signoutRedirect({
        post_logout_redirect_uri: config.redirect_uri,
      })
      .catch((error: Error) => {
        log(error);
      });
  };
  const { t } = useI18n();

  return (
    <Dropdown.Container navbar={true} as='li'>
      <Dropdown.Toggle
        id='profile-toggler'
        bsPrefix='toggle'
        as={Link}
        to='#!'
        className='pe-0 ps-2 nav-link'
      >
        <Icon icon='account-circle' size='lg' />
      </Dropdown.Toggle>

      <Dropdown.Menu className='dropdown-caret dropdown-menu-card  dropdown-menu-end'>
        <div className='bg-white rounded-2 py-2 dark__bg-1000'>
          <Dropdown.Item as='a' href='#!' onClick={logout} id='logout'>
            {t('app.common.navbar.top.profile_dropdown.logout')}
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown.Container>
  );
};

export default ProfileDropdown;
