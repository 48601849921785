import clsx from 'clsx';
import * as React from 'react';

type Props = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  justifyContent?: 'start' | 'end' | 'center' | 'between' | 'around' | '';
  inline?: boolean;
  alignItems?: 'start' | 'end' | 'center' | 'baseline' | 'stretch' | '';
  alignContent?:
    | 'start'
    | 'end'
    | 'center'
    | 'between'
    | 'around'
    | 'stretch'
    | '';
  wrap?: 'wrap-reverse' | 'wrap' | 'nowrap' | '';
  className?: string;
  tag?: string;
  breakpoint?: string;
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse' | '';
  ref?: React.RefObject<HTMLDivElement>;
};
const Flex = ({
  justifyContent = '',
  alignItems = '',
  alignContent = '',
  inline,
  wrap = '',
  className,
  tag: Tag = 'div',
  children,
  breakpoint,
  direction = '',
  ...rest
}: Props) => {
  return (
    // @ts-expect-error TODO
    <Tag
      className={clsx(
        {
          [`d-${breakpoint ? breakpoint + '-' : ''}flex`]: !inline,
          [`d-${breakpoint ? breakpoint + '-' : ''}inline-flex`]: inline,
          [`flex-${direction}`]: direction,
          [`justify-content-${justifyContent}`]: justifyContent,
          [`align-items-${alignItems}`]: alignItems,
          [`align-content-${alignContent}`]: alignContent,
          [`flex-${wrap}`]: wrap,
        },
        className,
      )}
      {...rest}
    >
      {children}
    </Tag>
  );
};

export default Flex;
